<template>
	<div>
		<div class="px-4 py-4">
			<h2 class="text-2xl font-medium text-gray-900">Related Activities</h2>
			<div class="flex justify-center h-full w-full">
				<div v-if="!activityStore.loading" class="flex w-full h-full">
					<div class="w-full">
						<div class="flex-1 mt-6 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 w-full md:rounded-lg">
							<table class="flex-1 min-w-full divide-y divide-gray-300">
								<thead class="bg-gray-50">
									<tr>
										<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">ID</th>
										<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Description</th>
										<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Type</th>
										<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Visibility</th>
										<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
										<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Last updated by</th>
										<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Created by</th>
										<th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"></th>
									</tr>
								</thead>
								<tbody v-if="activityStore.activity.child_activities.length > 0" class="divide-y divide-gray-200 bg-white">
									<tr
										v-for="(activity, index) in activityStore.activity.child_activities"
										:key="activity.id"
										class="cursor-pointer hover:bg-gray-100"
										:class="index % 2 === 0 ? 'border-gray-300' : 'border-gray-200'"
										:title="
											router.resolve({
												name: 'activity.details',
												params: {
													activity_type: route.params.activity_type,
													activity_id: activity.id,
												},
											}).fullPath
										"
										@click="
											router.push({
												name: 'activity.details',
												params: {
													activity_type: route.params.activity_type,
													activity_id: activity.id,
												},
											})
										"
									>
										<td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
											{{ activity.id }}
											<dl class="font-normal lg:hidden">
												<dt class="sr-only">Description</dt>
												<dd class="mt-1 truncate text-gray-700">{{ activity?.description ?? '' }}</dd>
												<dt class="sr-only sm:hidden">Type</dt>
												<dd class="mt-1 truncate text-gray-500 sm:hidden">{{ activity.template?.name }}</dd>
												<dt class="sr-only sm:hidden">Visibility</dt>
												<dd class="mt-1 truncate text-gray-500 sm:hidden">{{ ActivityVisibilityLabel[activity.visibility] }}</dd>
												<dt class="sr-only sm:hidden">Last updated by</dt>
												<dd class="mt-1 truncate text-gray-500 sm:hidden">{{ activity.updater.first_name }} {{ activity.updater.last_name }}</dd>
												<dt class="sr-only sm:hidden">Created by</dt>
												<dd class="mt-1 truncate text-gray-500 sm:hidden">{{ activity.creator.first_name }} {{ activity.creator.last_name }}</dd>
											</dl>
										</td>
										<td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{{ activity?.description ?? '' }}</td>
										<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ activity.template?.name }}</td>
										<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ ActivityVisibilityLabel[activity.visibility] }}</td>
										<td class="px-3 py-4 text-sm text-gray-500">
											<span v-if="activity.status == ActivityStatus.CREATING" class="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">{{ ActivityStatusLabel[activity.status] }}</span>
											<span v-else-if="activity.status == ActivityStatus.OPEN" class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">{{ ActivityStatusLabel[activity.status] }}</span>
											<span v-else-if="activity.status == ActivityStatus.RESOLVED" class="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">{{ ActivityStatusLabel[activity.status] }}</span>
											<span v-else-if="activity.status == ActivityStatus.CLOSED" class="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">{{ ActivityStatusLabel[activity.status] }}</span>
											<span v-else-if="activity.status == ActivityStatus.ARCHIVED" class="inline-flex rounded-full bg-black px-2 text-xs font-semibold leading-5 text-white">{{ ActivityStatusLabel[activity.status] }}</span>
										</td>
										<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ activity.updater.first_name }} {{ activity.updater.last_name }}</td>
										<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ activity.creator.first_name }} {{ activity.creator.last_name }}</td>
										<td class="px-3 py-4 text-sm text-gray-500 w-px">
											<div class="flex justify-end items-center">
												<FontAwesomeIcon :icon="faChevronRight" class="shrink-0 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
											</div>
										</td>
									</tr>
								</tbody>
								<tbody v-else class="divide-y divide-gray-200 bg-white">
									<tr>
										<td colspan="8">
											<div class="flex flex-row justify-center items-center my-6 font-semibold">
												<h1>You haven't created any {{ plur(route.params.activity_type.toString(), 2) }} yet!</h1>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div v-else class="flex flex-row justify-center items-center h-full"><Loading /></div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { useActivityStore } from '@modules/activities/store';
	import { ActivityStatus, ActivityStatusLabel, ActivityVisibilityLabel } from '@/types/activity';
	import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
	import plur from '@utils/usePlural';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import Loading from '@components/Loading.vue';
	import { useRoute, useRouter } from 'vue-router';

	const router = useRouter();
	const route = useRoute();
	const activityStore = useActivityStore();
</script>

<style scoped></style>
